@import "../../variables";

.footer-container {
  width: $display-max-width;
  color: $light-light;
  background-color: $hacs-blue;
  font-family: $content-font;

  .footer {
    height: 250px;
    padding: 0 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-family: $title-font;
  }

  p {
    padding: 6px 10px;
    margin: 0;
    font-weight: bold;
  }

  a,
  a:visited {
    color: $light-light;
    font-weight: 400;
  }

  .socials {
    padding: 8px 0;
    color: $light-light;

    a,
    a:visited {
      color: $light-light;
    }
  }
}
