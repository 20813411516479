.form-wrapper {
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control-small {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-group {
    margin-bottom: 1rem;
    margin-right: 5%;
    max-width: 50%;

    .form-group-title {
      margin-block-end: 1rem;
      cursor: pointer;
    }
  }

  .flex-row {
    justify-content: flex-start;
  }

  small {
    display: block;
    margin-top: 0.5rem;
    font-size: 80%;
    font-weight: 400;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    margin-bottom: 1rem;
  }

  small {
    margin: 0;
  }

  .btn {
    margin-top: 0.5rem;
    background-color: $hr;
    color: $title-text;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 10px;
  }
}

// Smartphone/Low-Res Tablets
@media only screen and (max-width: 768px) {
  .form-wrapper {    
    .form-group {
      max-width: none;
    }
  }
}
