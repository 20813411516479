.editable-group {
  padding: 5px 0;

  .editable {
    cursor: pointer;

    &:hover {
      color: $hacs-blue;
    }
  }

  .subtitle {
    color: $subtitle;
    font-weight: lighter;
  }

  span {
    position: relative;
    display: inline;
    margin: 1em 0;

    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f303";
      position: absolute;
      top: 3px;
      right: -24px;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .editable-group {
    span {
      &::after {
        display: none;
      }
    }
  }
}
