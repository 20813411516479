@import "../../variables";

.card {
  background-color: white;
  border-radius: 5px;
  max-width: card-max-width;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 40px;

  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 100%;
  }

  .name {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    font-family: $title-font;
    color: $title-text;
    margin-bottom: 4px;
  }

  .role {
    font-size: 18px;
    line-height: 25px;
    color: $subtitle;
    font-family: $content-font;
    margin: 0;
    font-weight: 100;
  }

  a,
  a:visited {
    color: $title-text;
    font-size: 24px;
    margin: auto 4px;
  }

  .connect {
    margin: 30px 0 10px 0;
  }
}
