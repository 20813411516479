@import "../../../_variables.scss";

.file-system {
  border: 1px solid $hacs-blue;
  width: 100%;
  height: auto;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;

  .navigation {
    justify-content: flex-start;
    .level-up-button {
      top: 100px;
      left: 10px;
    }
  }

  .folder-contents {
    align-items: flex-start;
  }

  .file-upload {
    justify-content: flex-start;
  }
}

