@import "../../variables";

@media only screen and (max-width: 768px) {
  .close-button {
    all: initial;
    height: 10px;
    width: 10px;
    background-color: transparent;
    color: $hacs-blue;
    font-family: $title-font;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 10px 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      all: initial;
      margin: 10px 10px;
    }
  }

  .icon {
    all: initial;
    &:hover {
      background-color: white;
      color: $hacs-blue;
    }
  }
}
