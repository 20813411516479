@import "../../variables";

.socials {
  font-size: 24px;
  color: $social;
  display: flex;
  align-items: right;
  height: 24px;
  padding-bottom: 16px;

  a,
  a:visited {
    color: $social;
  }
}
