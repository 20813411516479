.admin-panel {
  width: $display-max-width;
  padding: $full-screen-safe-zone;
  font-family: $title-font;

  .admin-edit {
    padding-left: 50px;
    border-left: 2px solid #495057;
  }

  .opportunities-edit {
    overflow-x: auto;
    align-items: flex-start;
  }

  .opportunities-edit {
    justify-content: flex-start;
    gap: 5%;
  }

  .admin-group {
    margin-bottom: 1rem;
    max-width: 50%;

    .admin-group-title {
      margin-block-end: 1rem;
      cursor: pointer;
    }
  }

  .image {
    max-width: 200px;
    max-height: 200px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .carousel {
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: left;
    width: 95vw;

    &::-webkit-scrollbar {
      width: 50px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $faded-blue;
      border-radius: 30px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $hacs-blue;
      }
    }
  }

  .button-container {
    justify-content: flex-start;
  }

  .tier-options {
    display: flex;
    justify-content: space-evenly;

    label {
      text-align: center;
    }

    input {
      margin: auto;
    }
  }
}

// Smartphone/Low-Res Tablets
@media only screen and (max-width: 768px) {
  .admin-panel {
    .admin-group {
      width: 80vw;
      max-width: none;
    }
  }
}
