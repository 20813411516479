@import "variables";
@import "elements";
@import "editable";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

//
// Component Styling
//

// Admin Page Styling
@import "src/components/AdminPage/AdminPage";

// Auth Styling
@import "src/components/auth/Login";

// Form Styling
@import "src/components/forms/form";

// Homepage Styling
@import "src/components/Footer/Footer";
@import "src/components/Header/Header";
@import "src/components/Homepage/Homepage";

// Media Management Styling
@import "src/components/MediaManagement/FileSystem/FileSystem";
@import "src/components/MediaManagement/FileEdit";
@import "src/components/MediaManagement/MediaManagement";

// Navigation Styling
@import "src/components/Navigation/Navigation";
@import "src/components/Navigation/BurgerMenu";

// Opportunities Styling
@import "src/components/Opportunities/Opportunities";

// Familias Styling
@import "src/components/Familias/Familias";

// Sponsorship Styling
@import "src/components/Sponsors/Sponsors";

// People Styling
@import "src/components/People/People";

// Partials Styling
@import "src/components/partials/Images/ImageCropper";
@import "src/components/partials/CloseButton";
@import "src/components/partials/Event";
@import "src/components/partials/Modal";
@import "src/components/partials/OfficerCard";
@import "src/components/partials/Socials";
@import "src/components/partials/Tweet";

// QR Code Styling
@import "src/components/QRCode/QRCode";

// Redirect Styling
@import "src/components/Redirect/Redirect";

//
// Testing Styling
//

// Testing Page Styling
@import "src/testing/TestPage";

.App {
  .full-site-view {
    justify-content: space-between;
    min-height: 100vh;
  }
}
