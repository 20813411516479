@import "../../variables";

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: $hacs-border;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    z-index: 1000;
  }

  i {
    padding: 0;
  }

  .content {
    height: 100%;
    width: 100%;
    justify-content: center;
    font-family: $content-font;
    text-align: center;
    padding: 10px 0;

    .image {
      width: 40%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .other-content {
      width: 40%;

      .links {
        padding-left: 10px;

        .link {
          margin: 10px;
        }
      }

      .title {
        font-family: $title-font;
      }

      .description {
        margin: 5px 10%;
      }

      .time {
        font-weight: bold;
        color: $hacs-blue;
        margin-bottom: 5px;
      }

      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        max-height: 40vh;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Modal {
    width: 90vw;

    .image {
      display: none;
    }

    .other-content {
      width: 100% !important;

      .links {
        flex-wrap: wrap;
      }
    }
  }
}
