@import "../../variables";

.sponsors {
  width: 95vw;
  padding: $full-screen-safe-zone;

  .description {
    margin-right: 20vw;

    img {
      width: 40vw;
      margin: auto;
      display: block;
    }
  }

  h1 {
    font-family: $title-font;
    font-size: 36px;
  }

  h3 {
    margin-bottom: 0.5em;
  }

  .tier-header {
    margin-top: 0.5em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 30px;
    border-bottom-width: 5px;
    border-bottom-color: $hacs-blue;
    border-bottom-style: solid;
    width: 30vw;
  }

  .sponsor {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    .sponsor-item {
      height: 350px;
      width: 300px;
    }

    h4 {
      visibility: hidden;
      text-align: center;
    }

    img {
      height: 270px;
      width: 270px;
      margin: auto;
      display: block;
      object-fit: contain;

    }
    
    img:hover {
      height: 280px;
      width: 280px;
      object-fit: contain;
    }

    .sponsor-item:hover {
      h4 {
        visibility: visible;
        text-align: center;
      }
    }
  }

  .packages {
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .package {
      padding:10px;
      border-width: 5px;
      border-top-color: $hacs-blue;
      border-right-color: $hacs-green;
      border-bottom-color: $hacs-red;
      border-left-color: $hacs-gold;
      border-style: solid;
      border-radius: 5px;
      width: 40vw;
      min-width: 300px;
      margin-bottom: 1em;
      font-family: $content-font;
    }

    h3, p {
      text-align: center;
    }

  }
}

@media only screen and (max-width: 768px) {
  .sponsors {

    .description {
      img {
        min-width: 300px;
      }
    }

    .sponsor {
      .sponsor-item:hover {
        h4{
          visibility: hidden;
        }
      }
    }
  }
}