@import "variables";

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
}

p,
label,
input {
  font-family: $content-font;
}

textarea {
  font-family: $content-font;
  line-height: normal;
  height: 5rem;
  margin-bottom: 1rem;
}

.main-content {
  min-height: 60vh;
  width: $display-max-width;
}

a {
  text-decoration: none;
  color: $hacs-blue;

  &:visited {
    color: currentColor;
  }
}

i {
  padding: 0 0.5rem 0 0.5rem;
}

button {
  border: $hacs-border;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  background-color: transparent;
  color: $hacs-blue;
  font-family: $title-font;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background-color: $hacs-blue;
    color: white;
  }
}

.button-container {
  gap: 10px;
  margin: 10px 0;
}

.icon {
  all: initial;
  &:hover {
    background-color: white;
    color: $hacs-blue;
  }
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-full {
  width: $display-max-width;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.align-left {
  width: 100%;
  text-align: left;
}

.fancy {
  text-decoration: none;
  position: relative;
  letter-spacing: 0.5px;

  &::after {
    content: " ";
    width: 0;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    margin: auto;
    transition: 0.2s all;
  }

  &:hover,
  &:focus,
  &:active {
    &::after {
      width: 100%;
    }
  }
}

.section-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-family: $title-font;
  position: relative;
  width: max-content;
  margin-bottom: 50px;

  &::after {
    content: " ";
    width: calc(100% + 15px);
    height: 3px;
    background-color: $hacs-blue;
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .main-content {
    width: $display-max-width-mobile;
  }
}
