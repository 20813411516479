@import "../../variables";

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $hacs-blue;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $hacs-gold;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $light-light;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $focus-blue;
  padding: 1em 1em 0;
  font-size: 1.15em;
  height: 50%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $focus-blue;
}

/* Wrapper for item list */
.bm-item-list {
  color: $light-light;
}

/* Individual item */
.bm-item {
  display: inline-block;

  &:focus {
    outline: none;
  }
}

/* Styling of overlay */
.bm-overlay {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .1) !important;
}

@media only screen and (max-width: 768px) {
  .bm-burger-button {
    right: 2.5%;
    top: 18px;
  }
}
