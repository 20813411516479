@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Slab:wght@100;400;700&display=swap");

/* Colors */
$hacs-blue: #27246a;
$hacs-red: #981b1e;
$hacs-gold: #eba222;
$hacs-green: #088c45;
$title-text: #3d4449;
$light: #aaaaaa;
$subtitle: #7f888f;
$hr: rgba(186, 186, 186, 0.75);
$light-light: #ededed;
$social: #bbbbbb;
$faded-blue: rgba(39, 36, 106, 0.1);
$faded: rgba(143, 143, 143, 0.4);
$focus-blue: #242246;
$shadow: none;

/* Fonts */
$title-font: "Open Sans", sans-serif;
$content-font: "Open Sans", sans-serif;

/* Sizing */
$nav-height: 50px;
$hacs-border: 2px solid $hacs-blue;
$display-max-width: 100%;
$display-max-height: 100vh;
$display-max-width-mobile: 100vw;
$full-screen-safe-zone: 0 2.5%;
$card-max-width: 380px;
